import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
import { getSdk as cachedGqlSdk } from '#gql/cached'
export const GqlSdks = {
  default: defaultGqlSdk,
  cached: cachedGqlSdk,
}
export const GqClientOps = {"default":["getContactFormPersons","getContactFormTopics","changePassword","companyUserList","contactUs","createCompany","createCompanyCustomer","createCompanyCustomers","createCustomerAddress","createPassword","createRole","customerData","deleteCustomerAddress","deleteCustomerById","deleteRole","getCompanies","getCompanyUserById","CustomerAddressesList","getCustomerIndividualProductsData","getCustomerOrder","getPermissionList","getRoles","getRolesList","newsletter","passwordReset","rawlplugSetConsents","receivablesList","refreshCustomerToken","revokeCustomerToken","setCompanyOnCustomer","setCustomerCompanies","updateCompany","updateCustomerAddress","updateCustomer","validateCustomersFromCsv","addToCart","applyCouponToCart","checkoutAgreements","customerCart","CustomerCartWithRawlplugAttributesFragment","CustomerCartWithoutRawlplugAttributesFragment","getBluemediaRedirectPaymentUrl","productChecker","placeOrder","removeCouponFromCart","removeItemsFromCart","reorderItemsUser","setBillingAddressOnCart","setCartDivisionMethodOnCart","setCustomerOrderNumberOnCart","setCustomerOrderCommentOnCart","setPaymentMethodOnCart","setShippingAddressesOnCart","setShippingMethodsOnCart","updateCartItems","categoryBreadcrumbs","customerRecentOrders","addToWishlist","customerWishlist","CustomerWishlistFragment","CustomerWishlistFragmentForAll","removeFromWishlist"],"cached":["catalogProducts","category","categoryFilters","categoryList","CategoryFields","magentoCategoryProductsList","magentoCategoryProductsListLimited","product","getProductByCategoryId","promotionProducts","relatedProducts","searchSuggestionsProducts","upsellProducts","getCmsBlocks","resolveCategory","resolveProduct","route","searchAggregations","storeConfig"]}
export const GqlCatalogProducts = (...params) => useGql()('catalogProducts', ...params)
export const GqlCategory = (...params) => useGql()('category', ...params)
export const GqlCategoryFilters = (...params) => useGql()('categoryFilters', ...params)
export const GqlCategoryList = (...params) => useGql()('categoryList', ...params)
export const GqlGetCmsBlocks = (...params) => useGql()('getCmsBlocks', ...params)
export const GqlGetProductByCategoryId = (...params) => useGql()('getProductByCategoryId', ...params)
export const GqlMagentoCategoryProductsList = (...params) => useGql()('magentoCategoryProductsList', ...params)
export const GqlMagentoCategoryProductsListLimited = (...params) => useGql()('magentoCategoryProductsListLimited', ...params)
export const GqlProduct = (...params) => useGql()('product', ...params)
export const GqlPromotionProducts = (...params) => useGql()('promotionProducts', ...params)
export const GqlRelatedProducts = (...params) => useGql()('relatedProducts', ...params)
export const GqlResolveCategory = (...params) => useGql()('resolveCategory', ...params)
export const GqlResolveProduct = (...params) => useGql()('resolveProduct', ...params)
export const GqlRoute = (...params) => useGql()('route', ...params)
export const GqlSearchAggregations = (...params) => useGql()('searchAggregations', ...params)
export const GqlSearchSuggestionsProducts = (...params) => useGql()('searchSuggestionsProducts', ...params)
export const GqlStoreConfig = (...params) => useGql()('storeConfig', ...params)
export const GqlUpsellProducts = (...params) => useGql()('upsellProducts', ...params)
export const GqlCustomerAddressesList = (...params) => useGql()('CustomerAddressesList', ...params)
export const GqlAddToCart = (...params) => useGql()('addToCart', ...params)
export const GqlAddToWishlist = (...params) => useGql()('addToWishlist', ...params)
export const GqlApplyCouponToCart = (...params) => useGql()('applyCouponToCart', ...params)
export const GqlCategoryBreadcrumbs = (...params) => useGql()('categoryBreadcrumbs', ...params)
export const GqlChangePassword = (...params) => useGql()('changePassword', ...params)
export const GqlCheckoutAgreements = (...params) => useGql()('checkoutAgreements', ...params)
export const GqlCompanyUserList = (...params) => useGql()('companyUserList', ...params)
export const GqlContactUs = (...params) => useGql()('contactUs', ...params)
export const GqlCreateCompany = (...params) => useGql()('createCompany', ...params)
export const GqlCreateCompanyCustomer = (...params) => useGql()('createCompanyCustomer', ...params)
export const GqlCreateCompanyCustomers = (...params) => useGql()('createCompanyCustomers', ...params)
export const GqlCreateCustomerAddress = (...params) => useGql()('createCustomerAddress', ...params)
export const GqlCreatePassword = (...params) => useGql()('createPassword', ...params)
export const GqlCreateRole = (...params) => useGql()('createRole', ...params)
export const GqlCustomerCart = (...params) => useGql()('customerCart', ...params)
export const GqlCustomerData = (...params) => useGql()('customerData', ...params)
export const GqlCustomerRecentOrders = (...params) => useGql()('customerRecentOrders', ...params)
export const GqlCustomerWishlist = (...params) => useGql()('customerWishlist', ...params)
export const GqlDeleteCustomerAddress = (...params) => useGql()('deleteCustomerAddress', ...params)
export const GqlDeleteCustomerById = (...params) => useGql()('deleteCustomerById', ...params)
export const GqlDeleteRole = (...params) => useGql()('deleteRole', ...params)
export const GqlGetBluemediaRedirectPaymentUrl = (...params) => useGql()('getBluemediaRedirectPaymentUrl', ...params)
export const GqlGetCompanies = (...params) => useGql()('getCompanies', ...params)
export const GqlGetCompanyUserById = (...params) => useGql()('getCompanyUserById', ...params)
export const GqlGetContactFormPersons = (...params) => useGql()('getContactFormPersons', ...params)
export const GqlGetContactFormTopics = (...params) => useGql()('getContactFormTopics', ...params)
export const GqlGetCustomerIndividualProductsData = (...params) => useGql()('getCustomerIndividualProductsData', ...params)
export const GqlGetCustomerOrder = (...params) => useGql()('getCustomerOrder', ...params)
export const GqlGetPermissionList = (...params) => useGql()('getPermissionList', ...params)
export const GqlGetRoles = (...params) => useGql()('getRoles', ...params)
export const GqlGetRolesList = (...params) => useGql()('getRolesList', ...params)
export const GqlNewsletter = (...params) => useGql()('newsletter', ...params)
export const GqlPasswordReset = (...params) => useGql()('passwordReset', ...params)
export const GqlPlaceOrder = (...params) => useGql()('placeOrder', ...params)
export const GqlProductChecker = (...params) => useGql()('productChecker', ...params)
export const GqlRawlplugSetConsents = (...params) => useGql()('rawlplugSetConsents', ...params)
export const GqlReceivablesList = (...params) => useGql()('receivablesList', ...params)
export const GqlRefreshCustomerToken = (...params) => useGql()('refreshCustomerToken', ...params)
export const GqlRemoveCouponFromCart = (...params) => useGql()('removeCouponFromCart', ...params)
export const GqlRemoveFromWishlist = (...params) => useGql()('removeFromWishlist', ...params)
export const GqlRemoveItemsFromCart = (...params) => useGql()('removeItemsFromCart', ...params)
export const GqlReorderItemsUser = (...params) => useGql()('reorderItemsUser', ...params)
export const GqlRevokeCustomerToken = (...params) => useGql()('revokeCustomerToken', ...params)
export const GqlSetBillingAddressOnCart = (...params) => useGql()('setBillingAddressOnCart', ...params)
export const GqlSetCartDivisionMethodOnCart = (...params) => useGql()('setCartDivisionMethodOnCart', ...params)
export const GqlSetCompanyOnCustomer = (...params) => useGql()('setCompanyOnCustomer', ...params)
export const GqlSetCustomerCompanies = (...params) => useGql()('setCustomerCompanies', ...params)
export const GqlSetCustomerOrderCommentOnCart = (...params) => useGql()('setCustomerOrderCommentOnCart', ...params)
export const GqlSetCustomerOrderNumberOnCart = (...params) => useGql()('setCustomerOrderNumberOnCart', ...params)
export const GqlSetPaymentMethodOnCart = (...params) => useGql()('setPaymentMethodOnCart', ...params)
export const GqlSetShippingAddressesOnCart = (...params) => useGql()('setShippingAddressesOnCart', ...params)
export const GqlSetShippingMethodsOnCart = (...params) => useGql()('setShippingMethodsOnCart', ...params)
export const GqlUpdateCartItems = (...params) => useGql()('updateCartItems', ...params)
export const GqlUpdateCompany = (...params) => useGql()('updateCompany', ...params)
export const GqlUpdateCustomer = (...params) => useGql()('updateCustomer', ...params)
export const GqlUpdateCustomerAddress = (...params) => useGql()('updateCustomerAddress', ...params)
export const GqlValidateCustomersFromCsv = (...params) => useGql()('validateCustomersFromCsv', ...params)